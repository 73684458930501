<template>
  <div class="container-fluid">
    <div class="page-header mb-1">
      <strong>การเงินสมาชิก</strong>
    </div>

    <div class="card p-2">

      <div class="d-flex justify-content-between align-items-center">
        <div style="width: 250px;" class="mb-2">
          <input type="text" v-model="filter" class="form-control" placeholder="ค้นหา...">
        </div>
      </div>

      <b-table
        class="table-finance-members table-info table-bordered"
        :fields="fields"
        :items="items"
        :show-empty="true"
        empty-text="ไม่มีข้อมูล"
        :fixed="true"
        hover
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
        @filtered="onFiltered"
      >
        <template #cell(index)="data">
          {{ (perPage*(currentPage-1)) + data.index + 1 }}
        </template>
        <template #cell(username)="data">
          <span>{{ data.item.user.username }}</span>
        </template>
        <template #cell(role)="data">
          <div>
            <AccountRole :role="data.item.role" />
          </div>
        </template>
        <template #cell(firstName)="data">
          {{ data.item.user.firstName }}
        </template>
        <template #cell(lastName)="data">
          {{ data.item.user.lastName || '-' }}
        </template>
        <template #cell(balance)="data">
          <span class="text-primary font-weight-bold">{{ data.item.balance | amountFormat }}</span>
        </template>
        <template #cell(manage)="data">
          <button class="btn btn-info btn-sm" @click="goToMemberFinance(data.item)">รายงานการเงิน</button>
          <button class="btn btn-success ml-1 btn-sm" @click="deposit(data.item)">เติมเงิน</button>
          <button class="btn btn-danger ml-1 btn-sm" @click="withdraw(data.item)">ถอนเงิน</button>
        </template>
      </b-table>

      <b-pagination
        v-model="currentPage"
        :total-rows="items.length"
        :per-page="perPage"
        align="right"
        size="md"
        class="mt-2 mb-0"
      ></b-pagination>
    </div>

    <DepositModal :data="depositItem" :is-show="isShowDepositModal" @close="modalClosed" />
    <WithdrawModal :data="withdrawItem" :is-show="isShowWithdrawModal" @close="modalClosed" />
  </div>
</template>
<script>
import ManageAccountService from '@/services/ManageAccountService'
import DepositModal from '@/views/manage-account/components/DepositModal'
import WithdrawModal from '@/views/manage-account/components/WithdrawModal'
import cAlert from '@/helpers/alert'

export default {
  name: 'FinanceMembers',
  components: {
    DepositModal,
    WithdrawModal
  },
  data() {
    return {
      isLoading: false,
      data: null,
      fields: [
        {key: 'index', label: 'ลำดับ'},
        {key: 'username', label: 'ชื่อผู้ใช้'},
        {key: 'role', label: 'ระดับ'},
        {key: 'firstName', label: 'ชื่อ'},
        {key: 'lastName', label: 'นามสกุล'},
        {key: 'balance', label: 'ยอดเงิน', tdClass: "text-right", thClass: "text-center"},
        {key: 'manage', label: '', tdClass: "text-center"}
      ],
      perPage: 20,
      currentPage: 1,
      filter: '',
      /**
       * เติมเงิน
       * @type {Object}
       */
      isShowDepositModal: false,
      depositItem: null,
      /**
       * ถอนเงิน
       * @type {Object}
       */
      isShowWithdrawModal: false,
      withdrawItem: null
    }
  },
  computed: {
    items() {
      if(!this.data)
        return []

      return this.data.accounts.map((a)=>{
        return {
          ...a,
          user: a.users[0]
        }
      })
    }
  },
  methods: {
    getReports() {
      ManageAccountService.getAccounts()
      .then((response)=>{
        if(response.success) {
          this.data = response.data
        }else{
          throw new Error(response.data)
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ผิดพลาด!',
          text: e?.message || 'โหลดข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    },
    onFiltered() {
      this.currentPage = 1
    },
    deposit(item) {
      this.depositItem = item
      this.isShowDepositModal = true
    },
    withdraw(item) {
      this.withdrawItem = item
      this.isShowWithdrawModal = true
    },
    modalClosed(needReload) {
      this.isShowDepositModal = false
      this.isShowWithdrawModal = false
      if(needReload) {
        this.getReports()
      }
    },
    goToMemberFinance(item) {
      this.$router.push({name: 'FinanceMember', params: {accountId:item._id}})
    }
  },
  mounted() {
    this.getReports()
  }
}
</script>
<style lang="scss">
.table-finance-members {
  margin-bottom: 0;
  thead {
    tr {
      th {
        font-size: 95%;
        text-align: center;
      }
      th:nth-child(1) {
        width: 70px;
      }
      // th:nth-child(2) {
      //   width: 180px;
      // }
      // th:nth-child(3) {
      //   width: 180px;
      // }
      // th:nth-child(4) {
      //   width: 200px;
      // }
      // th:nth-child(5) {
      //   width: 200px;
      // }
      th:nth-child(7) {
        width: 280px;
      }
    }
  }
  tbody {
    background-color: #FFF;

    tr {
      td {
        vertical-align: middle;
        text-align: center;
        font-size: 95%;

        .badge {
          font-size: 95%;
          font-weight: normal;
        }
      }
    }
  }
}
</style>
